import React from "react"
import {
    Button,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
  } from 'semantic-ui-react'

  import B2BLogo from "@images/B2BIntegratorLogo.svg"
  import HeaderRow from "../components/headerRow.js"

  import { Link } from 'gatsby'
  import styles from "./pricing.css"

  const PricingPage = ({ data }) => {
      const [visible, setVisible] = React.useState(false)
      const sayHello = () => {
          setVisible(!visible);
        };

      return (
        <Grid>
        <Grid.Row columns={2}>
            <Grid.Column mobile={14} computer={8}>
                <Image src={B2BLogo} as='a' href='/'/>
            </Grid.Column>
            <Grid.Column only="computer" computer={8}>
                <Menu secondary>
                    <Menu.Item as={Link} name='features'  to='/features'>Features</Menu.Item>
                    <Menu.Item as={Link} name='pricing'  to='/pricing'>Pricing</Menu.Item>
                    <Menu.Item name='resources'>Resources</Menu.Item>
                    <Menu.Menu position="right">
                        <Menu.Item as={Link} name='login' to='/login'>Login</Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Grid.Column>
            <Grid.Column only="tablet mobile" mobile={2}>
                <Menu secondary floated='right' >
                    <Menu.Item >
                        <Icon onClick={() => {sayHello()}} name="sidebar"></Icon>
                    </Menu.Item>
                </Menu>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column>
                <Sidebar.Pushable as={Segment}>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    direction="right"
                    onHide={() => setVisible(false)}
                    vertical
                    visible={visible}
                    width='thin'
                    inverted
                >
                    <Menu.Item as={Link} name='features'  to='/features'>Features</Menu.Item>
                    <Menu.Item as={Link} name='pricing'  to='/pricing'>Pricing</Menu.Item>
                    <Menu.Item as='a'>Resources</Menu.Item>
                    <Menu.Item as='a'>Login</Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={visible} >

                  <Grid stackable divided columns={4}>
                    <Grid.Row >
                        <Grid.Column>
                            <Grid>
                                <Grid.Row>
                                  <Grid.Column color='yellow'  verticalAlign="middle" textAlign="center">
                                    <Segment basic> <Header size="big"> Free Plan </Header></Segment>
                                    <Segment basic><Header>$0 / Month</Header></Segment>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      5 Trading Partners
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      1 Integration Point
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      1 gig of storage 100 megs of transfers / month
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                  <p>FTP Suite </p>
                                  <p>( FTP, FTP/s, SFTP)</p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      Forum based support
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="center">
                                    <Button color='yellow'><Button.Content style={{color:'Black'}}>Sign-up</Button.Content></Button>
                                  </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column>
                            <Grid>
                                <Grid.Row>
                                  <Grid.Column color='yellow'  verticalAlign="middle" textAlign="center">
                                    <Segment basic> <Header size="big"> Small Network </Header></Segment>
                                    <Segment basic><Header>$200 / Month</Header></Segment>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      10 Trading Partners
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      5 Integration Points
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      5 gigs of storage
                                      500 megs of transfers / month
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      <p>FTP Suite ( FTP, FTP/s, SFTP)</p>
                                      <p>HTTP/s AS2</p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      email support
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="center">
                                    <Button color='yellow'><Button.Content style={{color:'Black'}}>Sign-up</Button.Content></Button>
                                  </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column>
                            <Grid>
                                <Grid.Row>
                                  <Grid.Column color='yellow'  verticalAlign="middle" textAlign="center">
                                    <Segment basic> <Header size="big"> Medium Network </Header></Segment>
                                    <Segment basic><Header>$500 / Month</Header></Segment>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      50 Trading Partners
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      10 Integration Points
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      20 gigs of storage
                                      2 gigs of transfers / month
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      <p> FTP Suite ( FTP, FTP/s, SFTP) </p>
                                      <p> HTTP/s AS2  </p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      email support with 1 day SLA
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="center">
                                    <Button color='yellow'><Button.Content style={{color:'Black'}}>Sign-up</Button.Content></Button>
                                  </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column>
                            <Grid>
                                <Grid.Row>
                                  <Grid.Column color='yellow'  verticalAlign="middle" textAlign="center">
                                    <Segment basic> <Header size="big"> Large Network </Header></Segment>
                                    <Segment basic><Header>Tiered Usage based pricing</Header></Segment>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      Unlimited Trading Partners
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      100 Integration Points
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      unlimited storage,
                                      unlimited transfers / month
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                      <p> FTP Suite ( FTP, FTP/s, SFTP) </p>
                                      <p> HTTP/s AS2  </p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle" textAlign="center">
                                    premium support, 2 hr SLA
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column verticalAlign="center">
                                    <Button color='yellow'><Button.Content style={{color:'Black'}}>Sign-up</Button.Content></Button>
                                  </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Grid.Column>
        </Grid.Row>
    </Grid>
      )
    }
    export default PricingPage
